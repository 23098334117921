import React, { useState, useEffect } from 'react'

import all from 'src/images/calendar/all-in-one.svg'
import unite from 'src/images/calendar/calendars-unite.svg'
import plan from 'src/images/calendar/plan-anytime.svg'
import sideKick from 'src/images/calmax/sidekick.svg'
import rewards from 'src/images/calmax/rewards.svg'
import habit from 'src/images/calmax/habit-haven.svg'
import track from 'src/images/calendar/on-track.svg'
import allMobile from 'src/images/calendar/all-in-one-mobile.svg'
import uniteMobile from 'src/images/calendar/calendars-unite-mobile.svg'
import planMobile from 'src/images/calendar/plan-anytime-mobile.svg'
import sideKickMobile from 'src/images/calmax/sidekick-mobile.svg'
import rewardsMobile from 'src/images/calmax/rewards-mobile.svg'
import trackMobile from 'src/images/calendar/on-track-mobile.svg'
import { graphql, useStaticQuery } from 'gatsby'

import {
  ContentContainer,
  Header,
  ImageContainer,
  Title,
  Text,
  MobileImageContainer,
  PlusBubble,
  BubbleText,
  MealImageContainer,
  MealImage,
} from './styles'

import { CAL_MAX_FEATURES } from '../CalMaxFeatures/constants'

const CalendarNewFeatures = () => {
  const { meal } = useStaticQuery(graphql`
    query CalendarNewFeaturesQuery {
      meal: file(relativePath: { eq: "calmax/meal.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const [activeImageIndex, setActiveImageIndex] = useState(-1)

  const handleScroll = () => {
    const sections = document.querySelectorAll('.left-column section')
    let activeIndex = -1

    sections.forEach((section, index) => {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY
      const sectionHeight = section.offsetHeight

      if (
        window.scrollY + window.innerHeight / 2.3 >= sectionTop &&
        window.scrollY + window.innerHeight / 2.3 < sectionTop + sectionHeight
      ) {
        activeIndex = index
      }
    })

    setActiveImageIndex(activeIndex)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const featureImages = [all, unite, plan, sideKick, habit, rewards, meal, track]
  const featureMobileImages = [
    allMobile,
    uniteMobile,
    planMobile,
    sideKickMobile,
    habit,
    rewardsMobile,
    meal,
    trackMobile,
  ]

  return (
    <ContentContainer>
      <div className="container">
        <div className="left-column">
          <Header>Designed to Delight</Header>
          {CAL_MAX_FEATURES.map((feature, i) => (
            <section
              key={i}
              id={`section${i}`}
              className={activeImageIndex === i ? 'active-section' : 'inactive-section'}
            >
              {typeof featureMobileImages[i] === 'object' ? (
                <MobileImageContainer>
                  <MealImage
                    image={featureMobileImages[i].childImageSharp.gatsbyImageData}
                    alt={feature.alt}
                  />
                </MobileImageContainer>
              ) : (
                <MobileImageContainer
                  aria-label={feature.alt}
                  key={feature.alt}
                  type="image/svg+xml"
                  data={featureMobileImages[i]}
                >
                  svg-animation
                </MobileImageContainer>
              )}
              {feature.plus && (
                <PlusBubble blue>
                  <BubbleText>Calendar Plus</BubbleText>
                </PlusBubble>
              )}
              <Title>{feature.title}</Title>
              <Text>{feature.text}</Text>
            </section>
          ))}
        </div>
        <div className="right-column">
          {featureImages.map((image, index) => (
            <>
              {typeof image === 'object' ? (
                <MealImageContainer className={activeImageIndex === index ? 'active-image' : ''}>
                  <MealImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={CAL_MAX_FEATURES[index].alt}
                  />
                </MealImageContainer>
              ) : (
                <ImageContainer
                  aria-label={CAL_MAX_FEATURES[index].alt}
                  key={`${image.alt}-mobile`}
                  type="image/svg+xml"
                  data={image}
                  className={activeImageIndex === index ? 'active-image' : ''}
                  higher={index === 2 || index === 4 || index === 5}
                  highest={index === 3 || index === 7}
                >
                  <>svg-animation</>
                </ImageContainer>
              )}
            </>
          ))}
        </div>
      </div>
    </ContentContainer>
  )
}

export default CalendarNewFeatures
